import { InputAdornment } from '@mui/material';

export const declOfNum = (n, text_forms) => {
  n = Math.abs(n) % 100;
  var n1 = n % 10;
  if (n > 10 && n < 20) {
    return text_forms[2];
  }
  if (n1 > 1 && n1 < 5) {
    return text_forms[1];
  }
  if (n1 === 1) {
    return text_forms[0];
  }
  return text_forms[2];
};

export const blurOnEnter = (event) => {
  if (event.key === 'Enter') {
    event.target.blur();
  }
};

export const getInputAdornment = (adornment) => {
  return <InputAdornment position="end">{adornment}</InputAdornment>;
};

export const calcResults = (eventData) => {
  const calculated = { ...eventData };
  let { members, products } = calculated;

  const tempMembers = members.map((member) => ({
    ...member,
    usedSum: 0,
    boughtSum: 0,
    debts: [],
    boughtProducts: [],
    usedProducts: [],
  }));

  products.forEach((product) => {
    for (let key in product) {
      if (!product[key]) return;
    }
    const { name, customer, users, price, count } = product;
    const customerMember = tempMembers.find((member) => member._id === customer._id);
    if (!customerMember) return;
    const total = price * count;
    const costPerUser = total / users.length;
    if (!costPerUser) return;
    customerMember.boughtSum += total;
    customerMember.boughtProducts.push({
      name,
      sum: total,
    });
    users.forEach((user) => {
      const member = tempMembers.find((item) => item._id === user._id);
      member.usedSum += costPerUser;
      member.usedProducts.push({
        name,
        sum: costPerUser,
      });
    });
  });

  tempMembers.forEach(
    (member) => (member.debtTemp = member.debt = member.usedSum - member.boughtSum),
  );
  tempMembers.sort((a, b) => a.debtTemp - b.debtTemp);
  const plusArray = tempMembers.filter((member) => member.debtTemp > 0).reverse();
  const minusArray = tempMembers.filter((member) => member.debtTemp < 0);

  for (let i = 0; i < tempMembers.length; i++) {
    if (!plusArray[0] || !minusArray[0]) break;

    const minAbs = Math.min(plusArray[0].debtTemp, -minusArray[0].debtTemp);

    plusArray[0].debtTemp -= minAbs;
    minusArray[0].debtTemp += minAbs;

    plusArray[0].debts.push({
      name: minusArray[0].name,
      sum: minAbs,
    });

    minusArray[0].debts.push({
      name: plusArray[0].name,
      sum: minAbs,
    });

    if (plusArray[0].debtTemp < 0.0001) {
      plusArray.shift();
    }

    if (minusArray[0].debtTemp > -0.0001) {
      minusArray.shift();
    }
  }

  members = members.map((member) => {
    const temp = tempMembers.find((item) => item._id === member._id);
    return { ...temp };
  });

  return { ...calculated, members };
};

export const jsonEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

export const captureClick = (e) => {
  e.stopPropagation(); // Stop the click from being propagated.
  e.preventDefault();
  window.removeEventListener('click', captureClick, true); // cleanup
  window.removeEventListener('touchend', captureClick, true); // cleanup
};
