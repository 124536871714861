export const SHOW_TABS = "SHOW_TABS";
export const HIDE_TABS = "HIDE_TABS";
export const ASYNC_SHOW_TABS = "ASYNC_SHOW_TABS";
export const CHANGE_TAB_INDEX = "CHANGE_TAB_INDEX";

export const SHOW_NEW_EVENT_DIALOG = "SHOW_NEW_EVENT_DIALOG";
export const HIDE_NEW_EVENT_DIALOG = "HIDE_NEW_EVENT_DIALOG";

export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";

export const UPDATE_EVENT = "UPDATE_EVENT";

export const SHOW_ADD_MEMBER_DIALOG = "SHOW_ADD_MEMBER_DIALOG";
export const HIDE_ADD_MEMBER_DIALOG = "HIDE_ADD_MEMBER_DIALOG";
export const SHOW_MEMBER_DETAILS = "SHOW_MEMBER_DETAILS";
export const HIDE_MEMBER_DETAILS = "HIDE_MEMBER_DETAILS";
export const MEMBER_CLICK = "MEMBER_CLICK";
export const MEMBER_LONG_PRESS = "MEMBER_LONG_PRESS";
export const CLEAR_SELECTION = "CLEAR_SELECTION";
export const EDIT_MEMBER = "EDIT_MEMBER";
export const PRODUCT_CLICK = "PRODUCT_CLICK";
export const PRODUCT_LONG_PRESS = "PRODUCT_LONG_PRESS";
export const EDIT_CLICK = "EDIT_CLICK";
export const REMOVE_CLICK = "REMOVE_CLICK";

/** app */
export const SHOW_ERROR_DIALOG = "SHOW_ERROR_DIALOG";
export const HIDE_ERROR_DIALOG = "HIDE_ERROR_DIALOG";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

/** edit product dialog */
export const SHOW_EDIT_PRODUCT_DIALOG = "SHOW_EDIT_PRODUCT_DIALOG";
export const HIDE_EDIT_PRODUCT_DIALOG = "HIDE_EDIT_PRODUCT_DIALOG";
export const SET_PRODUCT_TO_EDIT = "SET_PRODUCT_TO_EDIT";
export const SET_NAME = "SET_NAME";
export const SET_PRICE = "SET_PRICE";
export const SET_COUNT = "SET_COUNT";
export const SET_CUSTOMER = "SET_CUSTOMER";
export const TOGGLE_USER = "TOGGLE_USER";
export const SELECT_ALL = "SELECT_ALL";
export const RESET_ALL = "RESET_ALL";
export const SAVE_PRODUCT = "SAVE_PRODUCT";
export const SET_PRODUCT = "SET_PRODUCT";
