import {
  SET_NAME,
  SET_PRICE,
  SET_COUNT,
  SET_CUSTOMER,
  TOGGLE_USER,
  SELECT_ALL,
  RESET_ALL,
  SET_PRODUCT,
} from "../types";

const initialState = {
  _id: "",
  name: "",
  customer: "",
  users: [],
  count: "1",
  price: "",
};

const updateUsers = ({ users }, id) => {
  if (!id) return;
  let updated = [...users];
  updated.includes(id) ? (updated = updated.filter((item) => item !== id)) : updated.push(id);
  return updated;
};

const setProduct = (state, payload) => {
  if (!payload) return { ...initialState };
  const newState = { ...state, ...payload };
  newState.customer = payload.customer._id;
  newState.users = payload.users.map((user) => user._id);
  return newState;
};

export const editProductReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_NAME:
      return { ...state, name: payload };
    case SET_PRICE:
      return { ...state, price: payload };
    case SET_COUNT:
      return { ...state, count: payload };
    case SET_CUSTOMER:
      return { ...state, customer: payload };
    case TOGGLE_USER:
      return { ...state, users: updateUsers(state, payload) };
    case SELECT_ALL:
      return { ...state, users: [...payload] };
    case RESET_ALL:
      return { ...state, users: [] };
    case SET_PRODUCT:
      return setProduct(state, payload);
    default:
      return state;
  }
};
