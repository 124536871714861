import { calcResults } from 'utils';
import {
  CREATE_EVENT_SUCCESS,
  UPDATE_EVENT,
  SHOW_ADD_MEMBER_DIALOG,
  HIDE_ADD_MEMBER_DIALOG,
  SHOW_MEMBER_DETAILS,
  HIDE_MEMBER_DETAILS,
  MEMBER_CLICK,
  MEMBER_LONG_PRESS,
  CLEAR_SELECTION,
  PRODUCT_CLICK,
  PRODUCT_LONG_PRESS,
  EDIT_CLICK,
  SHOW_EDIT_PRODUCT_DIALOG,
  HIDE_EDIT_PRODUCT_DIALOG,
} from '../types';

const initialState = {
  id: '',
  name: '',
  isNameDialogVisible: false,
  isMemberDetailsVisible: false,
  chosenMemberId: '',
  members: [
    // {
    //   name: 'initial state member name',
    //   _id: '123',
    // },
  ],
  products: [
    // {
    //   name: 'initial state product name',
    //   _id: '1234',
    // },
  ],
  selectedMembers: [],
  selectedProducts: [],
  isProductDialogVisible: false,
  telegramInviteUrl: '',
};

const memberClick = (state, id) => {
  let { selectedMembers } = state;
  const isSelectMode = selectedMembers.length > 0;
  if (isSelectMode) {
    selectedMembers.includes(id)
      ? (state.selectedMembers = selectedMembers.filter((item) => item !== id))
      : selectedMembers.push(id);
  } else {
    state.isMemberDetailsVisible = true;
    state.chosenMemberId = id;
  }
  return { ...state };
};

const memberLongPress = (state, id) => {
  let { selectedMembers } = state;
  selectedMembers.includes(id)
    ? (state.selectedMembers = selectedMembers.filter((item) => item !== id))
    : selectedMembers.push(id);
  return { ...state };
};

const productClick = (state, id) => {
  let { selectedProducts } = state;
  const isSelectMode = selectedProducts.length > 0;
  if (isSelectMode) {
    selectedProducts.includes(id)
      ? (state.selectedProducts = selectedProducts.filter((item) => item !== id))
      : selectedProducts.push(id);
  }
  return { ...state };
};

const productLongPress = (state, id) => {
  let { selectedProducts } = state;
  selectedProducts.includes(id)
    ? (state.selectedProducts = selectedProducts.filter((item) => item !== id))
    : selectedProducts.push(id);
  return { ...state };
};

const editClick = (state, payload) => {
  if (state.selectedMembers.length > 0) {
    return {
      ...state,
      isNameDialogVisible: true,
    };
  } else if (state.selectedProducts.length > 0) {
    return {
      ...state,
      isProductDialogVisible: true,
    };
  } else {
    return { ...state };
  }
};

export const eventReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_EVENT_SUCCESS:
      return { ...state, id: payload };
    case SHOW_ADD_MEMBER_DIALOG:
      return { ...state, isNameDialogVisible: true };
    case HIDE_ADD_MEMBER_DIALOG:
      return { ...state, isNameDialogVisible: false };
    case UPDATE_EVENT:
      document.title = payload.name ? `Baldej - ${payload.name}` : 'Baldej';
      const results = calcResults(payload);
      return { ...state, ...results, selectedMembers: [], selectedProducts: [] };
    case SHOW_MEMBER_DETAILS:
      return { ...state, isMemberDetailsVisible: true, chosenMemberId: payload };
    case HIDE_MEMBER_DETAILS:
      return { ...state, isMemberDetailsVisible: false };
    case MEMBER_CLICK:
      return memberClick(state, payload);
    case MEMBER_LONG_PRESS:
      return memberLongPress(state, payload);
    case CLEAR_SELECTION:
      return { ...state, selectedMembers: [], selectedProducts: [] };
    case PRODUCT_CLICK:
      return productClick(state, payload);
    case PRODUCT_LONG_PRESS:
      return productLongPress(state, payload);
    case EDIT_CLICK:
      return editClick(state);
    case SHOW_EDIT_PRODUCT_DIALOG:
      return { ...state, isProductDialogVisible: true };
    case HIDE_EDIT_PRODUCT_DIALOG:
      return { ...state, isProductDialogVisible: false };
    default:
      return state;
  }
};
