import { HIDE_NEW_EVENT_DIALOG, SHOW_NEW_EVENT_DIALOG } from "../types";

const initialState = {
  isDialogVisible: false,
};

export const newEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NEW_EVENT_DIALOG:
      return { ...state, isDialogVisible: true };
    case HIDE_NEW_EVENT_DIALOG:
      return { ...state, isDialogVisible: false };
    // case CHANGE_TAB_INDEX:
    //     return { ...state, selectedTabIndex: action.payload };
    default:
      return state;
  }
};
