import { HIDE_ERROR_DIALOG, HIDE_LOADER, SHOW_ERROR_DIALOG, SHOW_LOADER } from "../types";

const initialState = {
  error: "",
  isLoading: false,
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ERROR_DIALOG:
      return { ...state, error: action.payload };
    case HIDE_ERROR_DIALOG:
      return { ...state, error: "" };
    case SHOW_LOADER:
      return { ...state, isLoading: true };
    case HIDE_LOADER:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
