import React from "react";
import { Loading, Wrapper } from "./styled";

export const Loader = () => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Wrapper>
        <Loading />
      </Wrapper>
    </div>
  );
};
