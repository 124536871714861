import { combineReducers } from "redux";
import { appReducer } from "./appReducer";
import { editProductReducer } from "./editProductReducer";
import { eventReducer } from "./eventReducer";
import { newEventReducer } from "./newEventReducer";
import { tabsReducer } from "./tabsReducer";

export const rootReducer = combineReducers({
  app: appReducer,
  tabs: tabsReducer,
  newEvent: newEventReducer,
  event: eventReducer,
  editProduct: editProductReducer,
});
