import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  transform: translate(-50%, -50%);
`;

export const Loading = styled.div`
  border: 2px solid #00000020;
  border-left: 2px solid #00897b;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: ${spin} 1s infinite linear;
  box-sizing: border-box;
`;
