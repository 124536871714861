import React, { lazy, Suspense } from 'react';
import { isDesktop } from 'react-device-detect';
import { Loader } from './common/components/SplashLoader';

const Desktop = lazy(() => import('./Desktop'));
const Mobile = lazy(() => import('./Mobile'));

function App() {
  return <Suspense fallback={<Loader />}>{isDesktop ? <Mobile /> : <Mobile />}</Suspense>;
}

export default App;
