import { ASYNC_SHOW_TABS, HIDE_TABS, SHOW_TABS, CHANGE_TAB_INDEX } from "../types";

const initialState = {
  isTabsVisible: false,
  selectedTabIndex: 0,
};

export const tabsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ASYNC_SHOW_TABS:
    case SHOW_TABS:
      return { ...state, isTabsVisible: true };
    case HIDE_TABS:
      return { ...state, isTabsVisible: false };
    case CHANGE_TAB_INDEX:
      return { ...state, selectedTabIndex: action.payload };
    default:
      return state;
  }
};
